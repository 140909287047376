import * as React from "react";
import Layout from "../components/Layout";
import { StaticImage } from "gatsby-plugin-image";
import SEO from "../components/Seo";

const WhoWeAre = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="whoweare" />
      <main>
        <StaticImage src="../images/what_is_os.jpeg" alt="Who we are" />
      </main>
    </Layout>
  );
};
export default WhoWeAre;
